import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './assets/tailwind.css'
import * as Sentry from '@sentry/vue'
import { createGtm } from '@gtm-support/vue-gtm'

const app = createApp(App)
.use(router)
.use(createGtm({
  id: 'GTM-MNX46WV4',
  enabled: process.env.NODE_ENV === 'production', 
  debug: process.env.NODE_ENV !== 'production', 
  loadScript: true,
  vueRouter: router, 
  trackOnNextTick: true 
}))

Sentry.init({
  app,
  dsn: "https://da5347272be82d3e5701346bc27b2070@o4504238914535425.ingest.sentry.io/4505625005785088",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", "https:yourserver.io/api/"],
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

app.mount('#app')